import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"

import {UpNextCard} from "../../components/template-partials/global-components/footer-cards"


import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"
import hairLossTreating from "../../images/4.6-hair-loss.jpg";
import hairLossCoping from "../../images/4.6-hair-loss-coping.jpg";
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"


const HairLossPage = () => {
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Hair Loss" description="Hair thinning or hair loss is a common side effect of the chemotherapy and radiation therapy used to treat neuroblastoma. Learn more about how to help your child emotionally cope with this loss, as well as when to expect future hair growth." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Hair Loss">
        <h1 className="section__heading section__heading--green h1">Hair Loss</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="tc-barney h2 jost-med">Potential causes of hair loss </h2>
                <p><Link rel="glossary" to="/support-and-community/glossary/#chemotherapy">Chemotherapy</Link> and radiation therapy used in the treatment of neuroblastoma may cause hair loss or thinning of the hair. The specific type of chemotherapy and location of radiation therapy determines how likely this is. </p>
                <p>Hair loss can begin as soon as 7 to 10 days after treatment has started. Hair on other parts of the body may also be affected (eyebrows, eyelashes, and body hair). </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4 align-middle">
          <div className="columns small-12 large-4 text-center large-text-left">
            <FancyImage url={hairLossTreating} alt="Treating hair loss"/>
          </div>
          <div className="columns mt-4">
            <h3 className="tc-viridian h4 jost-med">Treating hair loss</h3>
            <p>Unfortunately, there is no way to prevent your child’s hair from falling out after they receive certain types of chemotherapy or radiation. When their hair is actively falling out it may bother them or make them feel itchy. Some children and parents prefer to have the child's hair cut as short as possible when hair starts to fall out. Some feel this can make it easier to manage. </p>
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <h3 className="tc-viridian h4 jost-med">Coping with hair loss</h3>
            <p>Hair loss may be emotional for you and your child. It may be helpful to talk to your child about how they feel about losing their hair and make sure they understand that it does grow back after treatment. Many children choose special hats or head coverings to make them feel comfortable and keep their head warm. </p>
            <p>Seeing other kids who are bald may help them feel more “normal”. Talk to your hospital <Link rel="glossary" to="/support-and-community/glossary/#child-life-specialist">child life specialist</Link> or social worker about books (with bald characters), toys (bald dolls or action figures), wigs, or other resources available to help your child adapt. </p>
          </div>
          <div className="columns small-12 large-4 text-center large-text-right mt-2 mb-2">
            <FancyImage url={hairLossCoping} alt="Coping with hair loss"/>
          </div>
        </div>
        <div className="row row--inner lg_mt-2">
          <div className="columns">
            <h3 className="tc-viridian h4 jost-med">How soon will my child’s hair grow back? </h3>
            <p>Your child’s hair will usually grow back a few months after the neuroblastoma treatments become milder or when they are finished. When your child’s hair grows back after treatment it may be slightly different than before—curlier, thicker, thinner, or a different color. </p>
          </div>
        </div>
        <AlertInfoCard className="lg_mt-2 lg_mb-2 mt-2 mb-2" text="Please talk with your child’s healthcare team if your child has any difficulty coping with changes to their appearance or hair loss.   "/>
        <SupportiveCareFooterLinks/>
      </Section>

      <InteriorPageFooter>

      <UpNextCard topic="Mouth Sores (Mucositis)" title="Managing mouth sores" lead="See how mouth sores are treated and how you can help managed them." url="/supportive-care/mouth-sores/" />

      </InteriorPageFooter>

    </Layout>
  )
}

export default HairLossPage
